import { ASPECT_RATIO } from '../consts/connection';

export const ROBOT_HOST = 'robot-host';

export function setRTCConnectionOptions(connection: any) {
	connection.socketURL = 'https://muazkhan.com:9001/';

	connection.session = {
		audio: true,
		video: true,
		data: true,
	};
	connection.autoCloseEntireSession = true;

	connection.sdpConstraints.mandatory = {
		OfferToReceiveAudio: true,
		OfferToReceiveVideo: true,
	};
	// connection.enableLogs = false;

	// connection.bandwidth = {
	// 	audio: 20,
	// 	video: 30, // 256 kbps
	// 	screen: 200, // 300 kbps
	// };

	connection.mediaConstraints = {
		audio: {
			mandatory: {
				echoCancellation: true, // disabling audio processing
				googAutoGainControl: true,
				googNoiseSuppression: true,
				googHighpassFilter: true,
				googTypingNoiseDetection: true,
			},
			optional: [],
		},
		video: {
			mandatory: {
				minWidth: 200,
				maxWidth: 450,
				minHeight: 150,
				maxHeight: 300,
				minFrameRate: 10,
				minAspectRatio: ASPECT_RATIO,
			},
			optional: [
				{
					facingMode: 'user', //or 'application' if back camera
				},
			],
		},
	};
}

export function setRTCConnectionRobotOptions(connection: any) {
	connection.userid = ROBOT_HOST;
}

export function setRTCConnectionClientOptions(connection: any, userid: string) {
	connection.userid = userid;
}

export function setRTCConnectionVideo(
	video: HTMLVideoElement,
	divVideoRef: React.MutableRefObject<HTMLDivElement | null>,
	videoRef?: React.MutableRefObject<HTMLVideoElement | null>,
	userID?: string,
) {
	video.setAttribute('width', '100%');
	video.setAttribute('height', '100%');
	video.setAttribute('position', 'relative');

	video.removeAttribute('controls');
	if (videoRef) videoRef.current = video;
	divVideoRef.current?.appendChild(video);
	if (userID) {
		const hoverEffect = document.createElement('div');
		hoverEffect.className = 'hover';
		const span = document.createElement('span');
		span.textContent = userID;
		hoverEffect.appendChild(span);
		divVideoRef.current?.appendChild(hoverEffect);
	}
}
