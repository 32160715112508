import { createTheme } from '@mui/material';
import { COLORS } from './colors';

const inputTheme = createTheme({
	components: {
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: COLORS.darkPlaceholder,
					'&.Mui-focused': {
						color: COLORS.darkText,
					},
				},
				filled: { color: COLORS.darkText },
			},
		},

		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					color: COLORS.darkText,
					backgroundColor: COLORS.darkSecondary,
					marginRight: '30px',
					fieldset: {
						borderColor: COLORS.darkText,
					},
					':hover': {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: COLORS.darkText,
						},
					},
					'&.Mui-focused': {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: COLORS.darkText,
						},
					},
				},
			},
		},
	},
});

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: COLORS.darkPrimary,
			dark: COLORS.darkPrimary,
		},
		secondary: {
			main: COLORS.darkSecondary,
			dark: COLORS.darkSecondary,
		},
	},
	components: {
		MuiDialog: {
			styleOverrides: {
				paper: {
					backgroundColor: COLORS.darkSecondary,
				},
			},
		},
		MuiDialogContentText: {
			styleOverrides: {
				root: {
					color: COLORS.darkText,
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					color: COLORS.darkText,
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: { '& .MuiButton-root': { color: COLORS.darkText } },
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					fontSize: '35px',
				},
			},
		},
		MuiIconButton: {
			variants: [
				{
					props: {
						className: 'margin',
					},
					style: { marginRight: '30px' },
				},
			],
			defaultProps: {
				size: 'large',
			},
			styleOverrides: {
				root: {
					backgroundColor: COLORS.darkSecondary,
					color: COLORS.darkText,
				},
			},
		},
		MuiButton: {
			variants: [
				{
					props: {
						variant: 'contained',
					},
					style: {
						color: COLORS.darkSecondary,
						borderColor: COLORS.darkSecondary,
						backgroundColor: COLORS.darkText,
						flex: 1,
						justifyContent: 'space-between',
						':hover': {
							backgroundColor: COLORS.darkText,
							opacity: 0.7,
						},
					},
				},
			],
		},
	},
});

export { darkTheme, inputTheme };
