import { Button, IconButton } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import ClientForm from '../types/ClientForm';
import {
	ArrowDownward,
	ArrowUpward,
	ControlCamera,
	MeetingRoom,
	NoMeetingRoom,
	TabletAndroid,
	VideogameAsset,
} from '@mui/icons-material';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import { ClipLoader } from 'react-spinners';
import { DOWN, UP, STOP, getLeftAndRightMotorSpeed } from '../utlis/bluetooth';
import { Joystick } from 'react-joystick-component';
import { LOADER_SIZE, OVERRIDE } from '../consts/loading';
import MessageModal from '../components/MessageModal';
import { createDraggableFunctions } from '../utlis/draggable';
import Draggable from '../types/Draggable';
import { DRAGGABLE_DEFAULT_VALUE } from '../consts/draggable';
import CustomTextField from '../components/CustomTextField';
import {
	DEFAULT_USER_FORM_PASSWORD_PROPS,
	DEFAULT_USER_FORM_ROOM_ID_PROPS,
	DEFAULT_USER_FORM_RULES,
	DEFAULT_USER_NAME_ROOM_ID_PROPS,
} from '../consts/form';
import { ControllerProps } from '../types/ControllerProps';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import RemoteVideo from '../types/RemoteVideo';

interface Props {
	localDivVideoRef: React.MutableRefObject<HTMLDivElement | null>;
	remoteDivVideoRef: React.MutableRefObject<HTMLDivElement | null>;

	isConnectionOpened: boolean;
	dialogMessage: { title: string; message: string } | undefined;
	isLoading: boolean;
	showBluetoothControls: boolean;
	userIDs: string[];
	remoteStreamInfosRef: React.MutableRefObject<{
		[userID: string]: RemoteVideo;
	}>;
	sendCommand: (message: string) => void;
	closeDialog: () => void;
	onCreateRoomClick: () => void;
	onCloseRoomClick: () => void;
}

function ClientScreen({
	localDivVideoRef,
	isConnectionOpened,
	dialogMessage,
	remoteDivVideoRef,
	isLoading,
	showBluetoothControls,
	userIDs,
	remoteStreamInfosRef,
	onCreateRoomClick,
	onCloseRoomClick,
	closeDialog,
	sendCommand,
}: Props) {
	const { control, getValues } = useFormContext<ClientForm>();
	const [showJoystick, setShowJoystick] = React.useState(false);
	const [showJoystickDelay, setShowJoystickDelay] = React.useState(false);
	const [showButtons, setShowButtons] = React.useState(false);

	const bottomRef = React.useRef<HTMLDivElement | null>(null);
	const topRef = React.useRef<HTMLDivElement | null>(null);

	const localDivIsMouseDragging = React.useRef<Draggable>({ ...DRAGGABLE_DEFAULT_VALUE });
	const joystickPosition = React.useRef<IJoystickUpdateEvent>();
	const joystickIntervalRef = React.useRef<NodeJS.Timer>();
	const upDownIntervalRef = React.useRef<NodeJS.Timer>();

	const windowHeight = useWindowHeight();
	const windowWidth = useWindowWidth();

	const roomIDControllerProps: ControllerProps<ClientForm> = React.useMemo(
		() => ({
			control: control,
			name: 'roomID',
			rules: DEFAULT_USER_FORM_RULES.roomID,
		}),
		[control],
	);

	const nameControllerProps: ControllerProps<ClientForm> = React.useMemo(
		() => ({
			control: control,
			name: 'name',
			rules: DEFAULT_USER_FORM_RULES.name,
		}),
		[control],
	);

	const passwordControllerProps: ControllerProps<ClientForm> = React.useMemo(
		() => ({
			control: control,
			name: 'password',
			rules: DEFAULT_USER_FORM_RULES.password,
		}),
		[control],
	);

	const remoteUserVideos = React.useMemo(() => {
		return userIDs.map((userID, index, arr) => (
			<div
				key={userID}
				ref={remoteStreamInfosRef.current[userID].divRef}
				className={`video ${
					arr.length <= 1 || ((windowWidth < 1000 || windowWidth < windowHeight) && !index) ? '-single ' : ''
				}${(windowWidth < 1000 || windowWidth < windowHeight) && index ? '-none ' : ''}`}
			></div>
		));
	}, [userIDs, remoteStreamInfosRef, windowWidth, windowHeight]);

	const setJoystickInterval = React.useCallback(() => {
		if (joystickIntervalRef.current) clearInterval(joystickIntervalRef.current);
		joystickIntervalRef.current = setInterval(() => {
			if (joystickPosition.current) {
				const { x: xValue, y: yValue } = joystickPosition.current;
				if (xValue && yValue) {
					const { leftMotor, rightMotor } = getLeftAndRightMotorSpeed(xValue * 2, yValue * 2);

					// if (Math.abs(Math.abs(leftMotor) - Math.abs(leftMotorPrev.current)) > 20) {
					// 	if (leftMotorPrev.current < 0) {
					// 		if (leftMotorPrev.current > leftMotor) leftMotorPrev.current = leftMotorPrev.current - 20;
					// 		else leftMotorPrev.current = leftMotorPrev.current + 20;
					// 	} else {
					// 		if (leftMotorPrev.current > leftMotor) leftMotorPrev.current = leftMotorPrev.current - 20;
					// 		else leftMotorPrev.current = leftMotorPrev.current + 20;
					// 	}
					// } else leftMotorPrev.current = leftMotor;

					// if (Math.abs(Math.abs(rightMotor) - Math.abs(rightMotorPrev.current)) > 20) {
					// 	if (rightMotorPrev.current < 0) {
					// 		if (rightMotorPrev.current > rightMotor)
					// 			rightMotorPrev.current = rightMotorPrev.current - 20;
					// 		else rightMotorPrev.current = rightMotorPrev.current + 20;
					// 	} else {
					// 		if (rightMotorPrev.current > rightMotor)
					// 			rightMotorPrev.current = rightMotorPrev.current - 20;
					// 		else rightMotorPrev.current = rightMotorPrev.current + 20;
					// 	}
					// } else rightMotorPrev.current = rightMotor;

					sendCommand(`R${rightMotor.toFixed(0)}L${leftMotor.toFixed(0)}`);
				}
			}
		}, 200);
	}, []);

	const clearJoystickInterval = React.useCallback(() => {
		if (joystickIntervalRef.current) {
			clearInterval(joystickIntervalRef.current);
			setTimeout(() => {
				sendCommand(STOP);
				setTimeout(() => {
					sendCommand(STOP);
				}, 100);
			}, 100);
		}
	}, [sendCommand]);

	React.useEffect(() => {
		if (!showBluetoothControls) {
			setShowJoystick(false);
			setShowButtons(false);
			setShowJoystickDelay(false);
		}
	}, [showBluetoothControls]);

	const { onMouseUp, onMouseDown, onMouseMove } = React.useMemo(
		() => createDraggableFunctions(localDivIsMouseDragging, localDivVideoRef),
		[localDivVideoRef],
	);

	return (
		<div className="container user-box" style={{ height: windowHeight, position: 'relative' }}>
			{!isConnectionOpened && (
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						bottom: 0,
						width: '100%',
						right: 0,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div
						style={{
							width: '20vw',
							height: '66vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<img src="./nikola.png" style={{ width: '20vw' }} />
					</div>
					<div
						style={{
							width: '20vw',
							height: '66vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<img src="./robot.png" style={{ width: '20vw' }} />
					</div>
					<div
						style={{
							width: '20vw',
							height: '66vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<img src="./Zaklada.png" style={{ width: '20vw' }} />
					</div>
				</div>
			)}
			{isConnectionOpened && (
				<div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 20px 0px 20px' }}>
					<img src="./nikola.png" style={{ width: '100px' }} />

					<h3 ref={topRef} className="title">
						Naziv sobe: <h2>{getValues().roomID}</h2>
					</h3>
					<img src="./Zaklada.png" style={{ width: '100px' }} />
				</div>
			)}
			<div style={{ display: 'flex', position: 'relative' }}>
				<div
					className="hide-scroll-bar"
					style={{
						width: windowWidth < windowHeight ? '100%' : 200,
						height: windowWidth < windowHeight ? 200 : '100%',
						display: 'flex',
						position: 'absolute',
						flexDirection: windowWidth < windowHeight ? 'row' : 'column',
						top: 20,
						left: 100,
						overflow: 'scroll',
						msOverflowStyle: 'none',
						scrollbarWidth: 'none',
					}}
				>
					{isConnectionOpened ? remoteUserVideos : null}
				</div>
				<div
					ref={remoteDivVideoRef}
					className="remote-video"
					style={{
						height:
							windowHeight -
							150 -
							(topRef.current?.offsetHeight || 0) -
							(bottomRef.current?.offsetHeight || 0),
						padding: userIDs.length ? '0px 200px' : '0px',
					}}
				></div>
			</div>
			<div
				ref={localDivVideoRef}
				onMouseUp={onMouseUp}
				onMouseDown={onMouseDown}
				onMouseMove={onMouseMove}
				className="local-video"
			>
				{isLoading && (
					<div className="loader">
						<ClipLoader color={'white'} size={LOADER_SIZE} css={OVERRIDE} />
					</div>
				)}
			</div>
			<div ref={bottomRef} className="bottom-wrapper">
				{!isConnectionOpened && (
					<div className="inputs-wrapper">
						<CustomTextField
							controlProps={nameControllerProps}
							textFieldProps={DEFAULT_USER_NAME_ROOM_ID_PROPS}
						/>
						<CustomTextField
							controlProps={roomIDControllerProps}
							textFieldProps={DEFAULT_USER_FORM_ROOM_ID_PROPS}
						/>
						<CustomTextField
							controlProps={passwordControllerProps}
							textFieldProps={{
								...DEFAULT_USER_FORM_PASSWORD_PROPS,
								onKeyPress: (e) => {
									if (e.key === 'Enter') onCreateRoomClick();
								},
							}}
						/>

						<IconButton onClick={onCreateRoomClick}>
							<MeetingRoom />
						</IconButton>
					</div>
				)}
				{isConnectionOpened && (
					<div className="buttons-wrapper">
						{showBluetoothControls && (
							<div className="control-button-wrapper">
								<IconButton
									onClick={() => {
										setShowButtons((curr) => !curr);
										setShowJoystick(false);
										setTimeout(() => setShowJoystickDelay(false), 500);
									}}
									className="margin"
								>
									<TabletAndroid />
								</IconButton>
								<div className={`control-wrapper ${showButtons ? 'show' : ''}`}>
									<div className="modal-control-wrapper">
										<span className="title">TABLET</span>
										<Button
											variant="contained"
											disabled={!showButtons}
											className="control-modal margin"
											onMouseDown={() => {
												upDownIntervalRef.current = setInterval(() => {
													sendCommand(UP);
												}, 100);
											}}
											onMouseUp={() => {
												sendCommand(STOP);
												if (upDownIntervalRef.current) clearInterval(upDownIntervalRef.current);
											}}
											startIcon={<ArrowUpward />}
										>
											Digni
										</Button>
										<Button
											variant="contained"
											disabled={!showButtons}
											className="control-modal"
											onMouseDown={() => {
												upDownIntervalRef.current = setInterval(() => {
													sendCommand(DOWN);
												}, 100);
											}}
											onMouseUp={() => {
												sendCommand(STOP);
												if (upDownIntervalRef.current) clearInterval(upDownIntervalRef.current);
											}}
											startIcon={<ArrowDownward />}
										>
											Spusti
										</Button>
									</div>
								</div>
							</div>
						)}
						{showBluetoothControls && (
							<div className="control-button-wrapper ">
								<IconButton
									onClick={() => {
										setShowJoystick((curr) => {
											if (curr) setTimeout(() => setShowJoystickDelay(false), 500);
											else setShowJoystickDelay(true);
											return !curr;
										});
										setShowButtons(false);
									}}
									className="margin"
								>
									<ControlCamera />
								</IconButton>
								<div className={`control-wrapper ${showJoystick ? 'show' : ''}`}>
									{showJoystickDelay && (
										<Joystick
											throttle={100}
											start={setJoystickInterval}
											move={(event) => (joystickPosition.current = { ...event })}
											stop={() => {
												clearJoystickInterval();
												sendCommand(STOP);
											}}
											baseColor="black"
											stickColor="white"
											disabled={!showJoystick}
										/>
									)}
								</div>
							</div>
						)}
						<IconButton onClick={onCloseRoomClick}>
							<NoMeetingRoom />
						</IconButton>
					</div>
				)}
			</div>
			{dialogMessage && (
				<MessageModal message={dialogMessage} closeDialog={closeDialog} closeButtonText="Zatvori" />
			)}
		</div>
	);
}

export default ClientScreen;
