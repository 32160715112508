import { TextFieldProps } from '@mui/material';
import ClientForm from '../types/ClientForm';
import RobotForm from '../types/RobotForm';

export const DEFAULT_ROBOT_FORM_VALUES: RobotForm = { roomID: '', password: '' };

export const DEFAULT_ROBOT_FORM_RULES = {
	roomID: { required: 'Ime sobe je potrebno' },
	password: { required: 'Lozinka je potrebna' },
};

export const DEFAULT_ROBOT_FORM_PASSWORD_PROPS: TextFieldProps = {
	label: 'Lozinka sobe',
	type: 'password',
	autoComplete: 'new-password',
};

export const DEFAULT_ROBOT_FORM_ROOM_ID_PROPS: TextFieldProps = {
	label: 'Ime sobe',
};

export const DEFAULT_USER_FORM_VALUES: ClientForm = { roomID: '', password: '', name: '' };

export const DEFAULT_USER_FORM_PASSWORD_PROPS: TextFieldProps = {
	label: 'Lozinka sobe',
	type: 'password',
	autoComplete: 'new-password',
};

export const DEFAULT_USER_FORM_RULES = {
	roomID: { required: 'Ime sobe je potrebno' },
	password: { required: 'Lozinka je potrebna' },
	name: { required: 'Ime je potrebno' },
};

export const DEFAULT_USER_FORM_ROOM_ID_PROPS: TextFieldProps = {
	label: 'Ime sobe',
};

export const DEFAULT_USER_NAME_ROOM_ID_PROPS: TextFieldProps = {
	label: 'Ime',
};
