import Message from '../types/Message';
import { ROBOT_HOST } from '../utlis/connection';

export const CONNECTION_WITH_ROBOT_BROKEN: Message = {
	message: `Veza sa robotom je prekinuta. Molimo Vas probajte ponovno`,
	title: 'Veza sa robotom prekinuta',
};

export const USER_NAME_SAME_AS_ROBOT: Message = {
	message: `Ime korisnika ne smije biti ${ROBOT_HOST}. Molimo Vas pokušajte ponovno s drugim korisničkim imenom.`,
	title: 'Greška pri povezivanju',
};

export const USER_NAME_SAME_ROOM: Message = {
	message: `Ime sobe i ime korisnika more biti različito. Molimo Vas pokušajte ponovno s drugim korisničkim imenom.`,
	title: 'Greška pri povezivanju',
};

export const INVALID_ROOM_PASSWORD = (roomID: string): Message => ({
	message: `Lozinka za pristup sobi ${roomID} nije točna.`,
	title: 'Greška pri povezivanju',
});

export const ERROR_CONNECTING = (roomID: string): Message => ({
	message: `Nešto je pošlo po zlu i ne možemo Vas spojiti sa sobom ${roomID}. Molimo Vas pokušajte ponovno.`,
	title: 'Greška pri povezivanju',
});

export const ERROR_CONNECTING_DIFFERENT_NAME = (roomID: string): Message => ({
	message: `Nešto je pošlo po zlu i ne možemo Vas spojiti sa sobom ${roomID}. Molimo Vas pokušajte ponovno s drugim korisničkim imenom.`,
	title: 'Greška pri povezivanju',
});

export const ROOM_DOESNT_EXIST = (roomID: string): Message => ({
	message: `Soba ${roomID} ne postoji. Pokušajte s drugim imenom sobe.`,
	title: 'Greška pri povezivanju',
});

export const BLUETOOTH_CONNECTION_WITH_ROBOT: Message = {
	message: `Bluetooth veza sa robotom je uspostavljena. Trenutno možete upravljati robotom.`,
	title: 'Bluetooth veza sa robotom uspostavljena',
};

export const BLUETOOTH_CONNECTION_WITH_ROBOT_BROKEN: Message = {
	message: `Bluetooth veza sa robotom je prekinuta. Ne možete više upravljati s robotom`,
	title: 'Bluetooth veza sa robotom prekinuta',
};
