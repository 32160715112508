import { TextField, TextFieldProps, ThemeProvider } from '@mui/material';
import { Controller, FieldValues, Path } from 'react-hook-form';
import { inputTheme } from '../consts/theme';
import { ControllerProps } from '../types/ControllerProps';

const CustomTextField: <
	TFieldValues extends FieldValues = FieldValues,
	TName extends Path<TFieldValues> = Path<TFieldValues>,
>(props: {
	controlProps: ControllerProps<TFieldValues, TName>;
	textFieldProps: TextFieldProps;
}) => React.ReactElement<any, string | React.JSXElementConstructor<any>> = ({ controlProps, textFieldProps }) => {
	return (
		<Controller
			{...controlProps}
			render={({ field, formState }) => (
				<ThemeProvider theme={inputTheme}>
					<TextField
						{...textFieldProps}
						value={field.value}
						onChange={field.onChange}
						helperText={formState.errors[controlProps.name] && formState.errors[controlProps.name].message}
						error={Boolean(formState.errors[controlProps.name])}
					/>
				</ThemeProvider>
			)}
		/>
	);
};

export default CustomTextField;
