import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { DEFAULT_USER_FORM_VALUES } from '../consts/form';
import {
	BLUETOOTH_CONNECTION_WITH_ROBOT,
	BLUETOOTH_CONNECTION_WITH_ROBOT_BROKEN,
	CONNECTION_WITH_ROBOT_BROKEN,
	ERROR_CONNECTING,
	ERROR_CONNECTING_DIFFERENT_NAME,
	INVALID_ROOM_PASSWORD,
	ROOM_DOESNT_EXIST,
	USER_NAME_SAME_AS_ROBOT,
	USER_NAME_SAME_ROOM,
} from '../consts/userMessages';

import ClientScreen from '../screens/ClientScreen';
import ClientForm from '../types/ClientForm';
import Message from '../types/Message';
import RemoteVideo from '../types/RemoteVideo';
import StreamEvent from '../types/StreamEvent';
import { STOP } from '../utlis/bluetooth';
import {
	ROBOT_HOST,
	setRTCConnectionClientOptions,
	setRTCConnectionVideo,
	setRTCConnectionOptions,
	setRTCConnectionRobotOptions,
} from '../utlis/connection';

function ClientContainer() {
	const rtcConnection = React.useRef<any>();
	const localDivVideoRef = React.useRef<HTMLDivElement | null>(null);
	const localVideoRef = React.useRef<HTMLVideoElement | null>(null);
	const remoteDivVideoRef = React.useRef<HTMLDivElement | null>(null);
	const remoteVideoRef = React.useRef<HTMLVideoElement | null>(null);
	const timeout = React.useRef<NodeJS.Timeout>();
	const roomDoesntExist = React.useRef(false);
	const userID = React.useRef('');

	const isJoined = React.useRef(false);

	const [isConnectionOpened, setIsConnectionOpened] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [showBluetoothControls, setShowBluetoothControls] = React.useState(false);
	const hadBluetoothControls = React.useRef(false);
	const [message, setMessage] = React.useState<Message | undefined>();
	const [userIDs, setUserIDs] = React.useState<string[]>([]);
	const remoteStreamInfosRef = React.useRef<{
		[userID: string]: RemoteVideo;
	}>({});

	const methods = useForm<ClientForm>({ defaultValues: DEFAULT_USER_FORM_VALUES });

	const sendCommand = React.useCallback(
		(message: string) => rtcConnection.current && rtcConnection.current.send(message),
		[],
	);

	const closeRTCRoom = React.useCallback(() => {
		if (rtcConnection.current && localDivVideoRef.current && localVideoRef.current) {
			setUserIDs([]);
			remoteStreamInfosRef.current = {};
			userID.current = '';
			sendCommand(STOP);
			setTimeout(() => {
				rtcConnection.current.attachStreams.forEach((stream: any) => stream.stop());
				rtcConnection.current.closeSocket();
				if (timeout.current) clearTimeout(timeout.current);
				try {
					if (remoteVideoRef.current && remoteDivVideoRef.current)
						remoteDivVideoRef.current.removeChild(remoteVideoRef.current);
					remoteVideoRef.current = null;
				} catch (e) {}
				try {
					localDivVideoRef.current!.removeChild(localVideoRef.current!);
					localVideoRef.current = null;
				} catch (e) {}
				hadBluetoothControls.current = false;
				setIsConnectionOpened(false);
				setShowBluetoothControls(false);
				isJoined.current = false;
			}, 100);
		}
	}, []);

	React.useEffect(() => {
		if (showBluetoothControls) setMessage(BLUETOOTH_CONNECTION_WITH_ROBOT);
		if (hadBluetoothControls.current && !showBluetoothControls) setMessage(BLUETOOTH_CONNECTION_WITH_ROBOT_BROKEN);

		hadBluetoothControls.current = showBluetoothControls;
	}, [showBluetoothControls]);

	React.useEffect(() => {
		//@ts-ignore
		const connection = new RTCMultiConnection();
		setRTCConnectionOptions(connection);
		setRTCConnectionRobotOptions(connection);
		connection.onleave = (event: StreamEvent) => {
			if (event.userid === ROBOT_HOST) {
				if (timeout.current) clearTimeout(timeout.current);
				setTimeout(() => setMessage(CONNECTION_WITH_ROBOT_BROKEN), 100);
				closeRTCRoom();
			}
		};

		connection.onmessage = (event: StreamEvent) => {
			if (event.userid === ROBOT_HOST) {
				const data = JSON.parse(event.data);
				if (data.bluetooth && (data.userid === userID.current || data.userid === methods.getValues().name))
					setShowBluetoothControls(true);
				else setShowBluetoothControls(false);
			}
		};

		connection.onstream = (event: StreamEvent) => {
			if (event.type === 'local') {
				setRTCConnectionVideo(event.mediaElement, localDivVideoRef, localVideoRef);
				setIsConnectionOpened(true);
				setIsLoading(false);
			}
			if (event.type === 'remote') {
				isJoined.current = true;
				if (event.userid === ROBOT_HOST)
					setRTCConnectionVideo(event.mediaElement, remoteDivVideoRef, remoteVideoRef);
				else {
					remoteStreamInfosRef.current[event.userid] = {
						video: event.mediaElement,
						divRef: React.createRef<HTMLDivElement | null>(),
						rendered: false,
						streamid: event.streamid,
						userid: event.userid,
					};
					setUserIDs((curr) => [...curr, event.userid]);
				}
			}
		};
		rtcConnection.current = connection;
	}, []);

	React.useEffect(() => {
		userIDs.forEach((userID) => {
			if (
				remoteStreamInfosRef.current[userID] &&
				!remoteStreamInfosRef.current[userID].rendered &&
				remoteStreamInfosRef.current[userID].divRef.current &&
				remoteStreamInfosRef.current[userID].video
			) {
				remoteStreamInfosRef.current[userID].rendered = true;
				setRTCConnectionVideo(
					remoteStreamInfosRef.current[userID].video!,
					remoteStreamInfosRef.current[userID].divRef,
					undefined,
					userID,
				);
			}
		});
	}, [userIDs]);

	const joinRTCRoom = React.useMemo(
		() =>
			methods.handleSubmit((data) => {
				if (data.name === ROBOT_HOST) {
					setMessage(USER_NAME_SAME_AS_ROBOT);
					return;
				}
				if (data.name === data.roomID) {
					setMessage(USER_NAME_SAME_ROOM);
					return;
				}
				if (rtcConnection.current) {
					setRTCConnectionClientOptions(rtcConnection.current, data.name);
					rtcConnection.current.password = data.password;
					setIsLoading(true);
					roomDoesntExist.current = false;
					timeout.current = setTimeout(() => {
						if (!isJoined.current && !roomDoesntExist.current) {
							setIsLoading(false);
							setMessage(ERROR_CONNECTING_DIFFERENT_NAME(data.roomID));
							rtcConnection.current.attachStreams.forEach((stream: any) => stream.stop());
							closeRTCRoom();
						}
					}, 10000);
					rtcConnection.current.checkPresence(data.roomID, (isRoomExist: boolean) => {
						if (isRoomExist)
							rtcConnection.current.join(data.roomID, (state: boolean, id: string, error: any) => {
								if (!state) {
									setIsLoading(false);
									if (error === 'Invalid password') setMessage(INVALID_ROOM_PASSWORD(data.roomID));
									else setMessage(ERROR_CONNECTING(data.roomID));
									rtcConnection.current.attachStreams.forEach((stream: any) => stream.stop());
									closeRTCRoom();
								}
								userID.current = id;
							});
						else {
							setIsLoading(false);
							setMessage(ROOM_DOESNT_EXIST(data.roomID));
							roomDoesntExist.current = true;
						}
					});
				}
			}),
		[methods, closeRTCRoom],
	);

	const removeMessage = React.useCallback(() => setMessage(undefined), []);

	return (
		<FormProvider {...methods}>
			<ClientScreen
				userIDs={userIDs}
				isLoading={isLoading}
				dialogMessage={message}
				localDivVideoRef={localDivVideoRef}
				remoteDivVideoRef={remoteDivVideoRef}
				isConnectionOpened={isConnectionOpened}
				remoteStreamInfosRef={remoteStreamInfosRef}
				showBluetoothControls={showBluetoothControls}
				sendCommand={sendCommand}
				closeDialog={removeMessage}
				onCloseRoomClick={closeRTCRoom}
				onCreateRoomClick={joinRTCRoom}
			/>
		</FormProvider>
	);
}

export default ClientContainer;
