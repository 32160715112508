import Draggable from '../types/Draggable';

export function createDraggableFunctions(
	divIsMouseDragging: React.MutableRefObject<Draggable>,
	divVideoRef: React.MutableRefObject<HTMLDivElement | null>,
) {
	function onMouseMove(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		if (!divIsMouseDragging.current.dragging) return;
		event.stopPropagation();
		event.preventDefault();
		if (divVideoRef.current) {
			divVideoRef.current.style.left = `${event.pageX - divIsMouseDragging.current.x}px`;
			divVideoRef.current.style.top = `${event.pageY - divIsMouseDragging.current.y}px`;
		}
	}

	function onMouseUp(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();
		divIsMouseDragging.current.dragging = false;
	}

	function onMouseDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();
		divIsMouseDragging.current.dragging = true;
		if (divVideoRef.current) {
			divIsMouseDragging.current.x = event.pageX - divVideoRef.current.offsetLeft;
			divIsMouseDragging.current.y = event.pageY - divVideoRef.current.offsetTop;
		}
	}

	return { onMouseMove, onMouseDown, onMouseUp };
}

export function createTouchDraggableFunctions(
	divIsMouseDragging: React.MutableRefObject<Draggable>,
	divVideoRef: React.MutableRefObject<HTMLDivElement | null>,
) {
	function onTouchMove(event: React.TouchEvent<HTMLDivElement>) {
		if (!divIsMouseDragging.current.dragging) return;
		event.stopPropagation();
		event.preventDefault();
		if (divVideoRef.current) {
			divVideoRef.current.style.left = `${event.changedTouches[0].clientX - divIsMouseDragging.current.x}px`;
			divVideoRef.current.style.top = `${event.changedTouches[0].clientY - divIsMouseDragging.current.y}px`;
		}
	}

	function onTouchEnd(event: React.TouchEvent<HTMLDivElement>) {
		event.stopPropagation();
		event.preventDefault();
		divIsMouseDragging.current.dragging = false;
	}

	function onTouchStart(event: React.TouchEvent<HTMLDivElement>) {
		event.stopPropagation();
		event.preventDefault();
		divIsMouseDragging.current.dragging = true;
		if (divVideoRef.current) {
			divIsMouseDragging.current.x = event.changedTouches[0].clientX - divVideoRef.current.offsetLeft;
			divIsMouseDragging.current.y = event.changedTouches[0].clientY - divVideoRef.current.offsetTop;
		}
	}

	return { onTouchMove, onTouchStart, onTouchEnd };
}
