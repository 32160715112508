import { ThemeProvider } from '@mui/material';
import React from 'react';
import { darkTheme } from './consts/theme';
import ClientContainer from './containers/ClientContainer';
import RobotContainer from './containers/RobotContainer';

function App() {
	React.useEffect(() => {
		const faviconTag = document.getElementById('favicon');
		const isDark = window.matchMedia('(prefers-color-scheme: dark)');
		if (faviconTag)
			if (isDark.matches)
				// @ts-ignore
				faviconTag.href = './robot_light.png';
			// @ts-ignore
			else faviconTag.href = './robot_dark.png';
	}, []);
	return (
		<ThemeProvider theme={darkTheme}>
			<ClientContainer />
		</ThemeProvider>
	);
}

export default App;
