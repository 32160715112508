import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Message from '../types/Message';

interface Props {
	message: Message;
	closeButtonText: string;
	closeDialog: () => void;
}

function MessageModal({ message, closeButtonText, closeDialog }: Props) {
	return (
		<Dialog open={true} onClose={closeDialog}>
			<DialogTitle>{message.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{message.message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeDialog}>{closeButtonText}</Button>
			</DialogActions>
		</Dialog>
	);
}

export default MessageModal;
