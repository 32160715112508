import React from 'react';
import { MY_BLUETOOTH_NAME, SEND_SERVICE, SEND_SERVICE_CHARACTERISTIC } from '../consts/bluetooth';

let commandCharacteristic: any = undefined;
let deviceBluetooth: any = undefined;

export const DOWN = 'Down';
export const UP = 'Up';
export const STOP = 'Stop';

export function closeBluetoothConnection() {
	commandCharacteristic = undefined;
	if (deviceBluetooth.gatt.connected) {
		deviceBluetooth.gatt.disconnect();
	}
}

export function onBluetoothMessage(event: any) {
	// kada primiš podatak
	const data_received = event.data;
	// var tekst =  document.getElementById("text_container").innerHTML;  // stavlja primljene podatke u div za provjeru
	//document.getElementById("text_container").innerHTML = tekst + data_received + " ";

	// ako primljeni podatak zapoèinje s R, potrebno je poslati znak po znak
	if (data_received.charAt(0) === 'R') {
		sendCommand(
			'0x' + data_received.charCodeAt(0).toString(16),
			'0x' + data_received.charCodeAt(1).toString(16),
			'0x' + data_received.charCodeAt(2).toString(16),
			'0x' + data_received.charCodeAt(3).toString(16),
			'0x' + data_received.charCodeAt(4).toString(16),
			'0x' + data_received.charCodeAt(5).toString(16),
			'0x' + data_received.charCodeAt(6).toString(16),
			'0x' + data_received.charCodeAt(7).toString(16),
			'0x' + data_received.charCodeAt(8).toString(16),
			'0x' + data_received.charCodeAt(9).toString(16),
		);
		return;
	}

	switch (
		data_received // pozovi funkciju koja salje podatak bluetoothu
	) {
		case DOWN:
			moveDown();
			break;
		case UP:
			moveUp();
			break;
		case STOP:
			moveStop();
			break;
		default:
			break;
	}
}

function sendCommand(...bytes: any[]) {
	var buffer = new ArrayBuffer(bytes.length);
	var view = new Uint8Array(buffer);
	for (let i = 0; i < bytes.length; i++) {
		view[i] = bytes[i];
	}
	return commandCharacteristic
		.writeValue(buffer)
		.catch((err: any) => console.log('Error when writing command! ', err));
}

function moveUp() {
	sendCommand(0x75, 0x70); //0x75-u   0x70-p  ASCII tablica    https://onlineasciitools.com/convert-ascii-to-hexadecimal
}
function moveDown() {
	sendCommand(0x64, 0x77, 0x6e); //0x64-d   0x77-w   0x6E-n
}
function moveStop() {
	sendCommand(0x73, 0x74, 0x70); // s t p
}

export function tryToConnectWithBlueTooth(
	connection: any,
	userIDs: string[],
	setBluetoothConnected: () => void,
	setErrorMessage: (message: { title: string; message: string }) => void,
	closeBluetooth: () => void,
	bluetoothConnectionCheckInterval: React.MutableRefObject<NodeJS.Timeout | undefined>,
) {
	//@ts-ignore
	if (!navigator.bluetooth) {
		setErrorMessage({
			title: 'Greška pri povezivanju s bluetooth-om',
			message: 'Web Bluetooth je isključen. Molimo Vas da odete na chrome://flags i omogućite Bluetooth.',
		});
		return;
	}
	//@ts-ignore
	navigator.bluetooth
		.requestDevice({
			filters: [{ name: MY_BLUETOOTH_NAME }, { services: [SEND_SERVICE] }],
		})
		.then((device: any) => {
			deviceBluetooth = device;
			return device.gatt.connect();
		})
		.then((server: any) => {
			return server.getPrimaryService(SEND_SERVICE);
		})
		.then((service: any) => {
			return service.getCharacteristic(SEND_SERVICE_CHARACTERISTIC);
		})
		.then((characteristic: any) => {
			if (userIDs.length) connection.send(JSON.stringify({ bluetooth: true, userid: userIDs[0] }));
			connection.onmessage = onBluetoothMessage;
			commandCharacteristic = characteristic;
			setErrorMessage({
				title: 'Uspješno povezivanje s bluetooth-om',
				message: 'Web Bluetooth je uključen i povezan sa Robotom.',
			});
			setBluetoothConnected();
			if (bluetoothConnectionCheckInterval.current) clearInterval(bluetoothConnectionCheckInterval.current);
			bluetoothConnectionCheckInterval.current = setInterval(() => {
				if (!deviceBluetooth.gatt.connected) {
					closeBluetooth();
					setErrorMessage({
						message: `Bluetooth veza sa robotom je prekinuta. Niste vise spojeni s robotom`,
						title: 'Bluetooth veza sa robotom prekinuta',
					});
				}
			}, 1000);
		})
		.catch((error: any) => {
			setErrorMessage({
				title: 'Greška pri povezivanju s bluetooth-om',
				message: 'Nešto je pošlo po zlu pri povezivanju s bluetooth-om. Molimo Vas pokušajte ponovno!',
			});
		});
}

export function getLeftAndRightMotorSpeed(xValue: number, yValue: number) {
	let modXvrj = 0;

	// raèunanje modificiranog X
	if (xValue > 0 && yValue > 0) {
		// prvi kvadrant
		if (xValue > yValue) modXvrj = yValue;
		else modXvrj = xValue;
	} else if (xValue < 0 && yValue > 0) {
		// drugi kvadrant
		if (xValue < -yValue) modXvrj = -yValue;
		else modXvrj = xValue;
	} else if (xValue < 0 && yValue < 0) {
		// treæi kvadrant
		if (Math.abs(xValue) > Math.abs(yValue)) modXvrj = yValue;
		else modXvrj = xValue;
	} else if (xValue > 0 && yValue < 0) {
		// èetvrti kvadrant
		if (xValue > -yValue) modXvrj = -yValue;
		else modXvrj = xValue;
	}
	if (xValue === 0) modXvrj = 0;

	let amplitude = Math.hypot(modXvrj, yValue);
	let angle = (180 / Math.PI) * Math.atan2(yValue, modXvrj);

	let rightMotor = 0;
	let leftMotor = 0;

	if (angle >= 0 && angle <= 90) {
		leftMotor = amplitude;
		rightMotor = amplitude - amplitude * Math.cos(Math.atan2(yValue, modXvrj));

		return { rightMotor: Math.min(100, rightMotor), leftMotor: Math.min(100, leftMotor) };
	}

	if (angle <= 0 && angle >= -90) {
		leftMotor = -amplitude;
		rightMotor = -(amplitude - amplitude * Math.cos(Math.atan2(yValue, modXvrj)));

		return { rightMotor: Math.max(-100, rightMotor), leftMotor: Math.max(-100, leftMotor) };
	}
	if (angle >= 90 && angle <= 180) {
		leftMotor = amplitude - amplitude * Math.cos(Math.PI - Math.atan2(yValue, modXvrj));
		rightMotor = amplitude;

		return { rightMotor: Math.min(100, rightMotor), leftMotor: Math.min(100, leftMotor) };
	}

	leftMotor = -(amplitude - amplitude * Math.cos(Math.PI - Math.atan2(yValue, modXvrj)));
	rightMotor = -amplitude;

	return { rightMotor: Math.max(-100, rightMotor), leftMotor: Math.max(-100, leftMotor) };
}

// export function getLeftAndRightMotorSpeed(xValue: number, yValue: number) {
// 	let modXvrj = 0;
// 	let Xvrj = xValue;
// 	let Yvrj = yValue;
// 	let Amplituda = 0;
// 	let Kut = 0;
// 	let Left_motor = 0;
// 	let Right_motor = 0;

// 	if (Xvrj > 0 && Yvrj > 0) {
// 		// prvi kvadrant
// 		if (Xvrj > Yvrj) {
// 			modXvrj = Yvrj;
// 		} else {
// 			modXvrj = Xvrj;
// 		}
// 	} else if (Xvrj < 0 && Yvrj > 0) {
// 		// drugi kvadrant
// 		if (Xvrj < -Yvrj) {
// 			modXvrj = -Yvrj;
// 		} else {
// 			modXvrj = Xvrj;
// 		}
// 	} else if (Xvrj < 0 && Yvrj < 0) {
// 		// treæi kvadrant
// 		if (Math.abs(Xvrj) > Math.abs(Yvrj)) {
// 			modXvrj = Yvrj;
// 		} else {
// 			modXvrj = Xvrj;
// 		}
// 	} else if (Xvrj > 0 && Yvrj < 0) {
// 		// èetvrti kvadrant
// 		if (Xvrj > -Yvrj) {
// 			modXvrj = -Yvrj;
// 		} else {
// 			modXvrj = Xvrj;
// 		}
// 	}
// 	if (Xvrj == 0) modXvrj = 0;

// 	Amplituda = Math.hypot(modXvrj, Yvrj);

// 	Kut = (180 / Math.PI) * Math.atan2(Yvrj, modXvrj);

// 	// lijevi motor
// 	if (Kut >= 0 && Kut <= 90) {
// 		Left_motor = Amplituda;
// 		//	l_motor.value = Left_motor;
// 		if (Left_motor > 100) {
// 			Left_motor = 100;
// 			//	   l_motor.value = Left_motor;
// 		}
// 	}
// 	if (Kut <= 0 && Kut >= -90) {
// 		Left_motor = -Amplituda;
// 		//	l_motor.value = Left_motor;
// 		if (Left_motor < -100) {
// 			Left_motor = -100;
// 			//	  l_motor.value = Left_motor;
// 		}
// 	}
// 	if (Kut >= 90 && Kut <= 180) {
// 		Left_motor = Amplituda - Amplituda * Math.cos(Math.PI - Math.atan2(Yvrj, modXvrj));
// 		//	l_motor.value = Left_motor;
// 		if (Left_motor > 100) {
// 			Left_motor = 100;
// 			//	   l_motor.value = Left_motor;
// 		}
// 	}
// 	if (Kut <= -90 && Kut >= -180) {
// 		Left_motor = -(Amplituda - Amplituda * Math.cos(Math.PI - Math.atan2(Yvrj, modXvrj)));
// 		//	l_motor.value = Left_motor;
// 		if (Left_motor < -100) {
// 			Left_motor = -100;
// 			//	  l_motor.value = Left_motor;
// 		}
// 	}

// 	// desni motor
// 	if (Kut >= 0 && Kut <= 90) {
// 		Right_motor = Amplituda - Amplituda * Math.cos(Math.atan2(Yvrj, modXvrj));
// 		//	r_motor.value = Right_motor;
// 		if (Right_motor > 100) {
// 			Right_motor = 100;
// 			//	  r_motor.value = Right_motor;
// 		}
// 	}
// 	if (Kut <= 0 && Kut >= -90) {
// 		Right_motor = -(Amplituda - Amplituda * Math.cos(Math.atan2(Yvrj, modXvrj)));
// 		//	r_motor.value = Right_motor;
// 		if (Right_motor < -100) {
// 			Right_motor = -100;
// 			//	  r_motor.value = Right_motor;
// 		}
// 	}
// 	if (Kut >= 90 && Kut <= 180) {
// 		Right_motor = Amplituda;
// 		//	r_motor.value = Right_motor;
// 		if (Right_motor > 100) {
// 			Right_motor = 100;
// 			//	  r_motor.value = Right_motor;
// 		}
// 	}
// 	if (Kut <= -90 && Kut >= -180) {
// 		Right_motor = -Amplituda;
// 		//	r_motor.value = Right_motor;
// 		if (Right_motor < -100) {
// 			Right_motor = -100;
// 			//	  r_motor.value = Right_motor;
// 		}
// 	}

// 	return { rightMotor: Right_motor, leftMotor: Left_motor };
// }
